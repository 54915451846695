<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.account" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">角色：</span>
        <el-select v-model="search.role_id" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="0" label="全部" :value="0"></el-option>
          <el-option v-for="item in roleArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">精准：</span>
        <el-checkbox v-model="search.checked" :true-label="1" :false-label="0" size="medium"></el-checkbox>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true">添加管理员</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="admin_id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="role" label="角色" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="login_time" label="最近登录时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="uptime" label="最近活跃时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="login_ip" label="当前IP" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.status == '正常' ? '#33C933' : '#F33333' }">{{scope.row.status}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="is_otp" label="谷歌验证器" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.is_otp? '#33C933' : '' }">{{scope.row.is_otp?"绑定":"未绑定"}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" v-if="scope.row.admin_id != 1" @click="(dialogReset = true), (row = scope.row)">重置密码</span>
          <span class="operation" v-if="scope.row.admin_id != 1" @click="freezeThaw(scope.row,scope.row.status == '正常' ? '冻结' : '解冻')">{{scope.row.status == '正常' ? '冻结' : '解冻'}}</span>
          <span class="operation" v-if="scope.row.admin_id != 1" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog title="添加管理员" v-model="dialogAdd" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">账号：</div>
        <el-input v-model="dialogArr.account" placeholder="请填写账号" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">昵称：</div>
        <el-input v-model="dialogArr.nickname" placeholder="请填写昵称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">密码：</div>
        <el-input v-model="dialogArr.password" placeholder="请填写密码" size="small" show-password></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">角色：</div>
        <el-select v-model="dialogArr.role_id" filterable placeholder="未选中" size="medium" clearable>
          <el-option v-for="item in roleArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">谷歌验证码：</div>
        <el-input v-model="dialogArr.google_code" placeholder="请填写谷歌验证码" size="small" ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add">确认添加</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog custom-class="dialog" title="重置密码" v-model="dialogReset" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">密码：</div>
        <el-input v-model="resetArr.password" placeholder="密码" size="small" show-password></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">确认密码：</div>
        <el-input v-model="resetArr.confirm" placeholder="确认密码" size="small" show-password></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">谷歌验证码：</div>
        <el-input v-model="resetArr.google_code" placeholder="谷歌验证码" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="reset">确认重置</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  getRole,
  add,
  freezeThaw,
  del,
  reset,
} from "@/api/admin/list";
import page from "@/components/page";
export default {
  name: "adminList",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        account: "", //管理员账号
        role_id: 0, //角色id
        checked: 1, //是否精确搜索
      },
      tableData: [{}], //数据
      row: [], //选中的数据
      roleArr: [], //角色数组
      dialogAdd: false, //添加弹出层
      dialogReset: false, //重置密码弹出层
      dialogArr: { account: "", nickname: "", password: "", role_id: "" ,google_code:""}, //添加数组
      resetArr: {
        //重置密码
        password: "",
        confirm: "",
        google_code:"",
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
    this.getRole();
  },
  methods: {
    //获取管理员数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "account", val: this.search.account },
        { key: "role", val: this.search.role_id || 0 },
        { key: "checked", val: this.search.checked },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取角色数据
    getRole() {
      getRole()
        .then((res) => {
          if (res.code == 0) {
            this.roleArr = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加管理员
    add() {
      add([
        { key: "account", val: this.dialogArr.account },
        { key: "nickname", val: this.dialogArr.nickname },
        { key: "password", val: this.dialogArr.password },
        { key: "role", val: this.dialogArr.role_id },
        { key: "google_code", val: this.dialogArr.google_code },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //冻结*解冻
    freezeThaw(row, str) {
      this.row = row;
      if (row.id == 1) {
        // 超级管理员不可被冻结
        this.$message({ message: "超级管理员无法冻结", type: "error" });
      } else {
        this.$confirm(
          "你确定要" + str + "管理员：" + row.account + "吗？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            freezeThaw([{ key: "admin_id", val: row.admin_id }])
              .then((res) => {
                if (res.code == 0) {
                  this.getList();
                  this.$message({ message: res.data, type: "success" });
                } else {
                  this.$message({ message: res.msg, type: "error" });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //删除管理员
    del(row) {
      this.row = row;
      if (row.id == 1) {
        // 超级管理员不可被删除
        this.$message({ message: "超级管理员无法删除", type: "error" });
      } else {
        this.$confirm(
          "你确定要删除管理员：" +
            row.account +
            "吗？此操作无法撤回,请谨慎操作",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            del([{ key: "admin_id", val: row.admin_id }])
              .then((res) => {
                if (res.code == 0) {
                  this.getList();
                  this.$message({ message: res.msg, type: "success" });
                } else {
                  this.$message({ message: res.msg, type: "error" });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //关闭弹窗清空数据
    closeDialog() {
      this.dialogArr = { account: "", nickname: "", password: "", role_id: "" }; //清空添加数据
      this.row = []; //清空选中数据
    },
    //重置密码
    reset() {
      if (this.resetArr.password != this.resetArr.confirm) {
        this.$message({
          message: "密码不一致",
          type: "error",
        });
        return;
      }
      reset([
        { key: "password", val: this.resetArr.password },
        { key: "admin_id", val: this.row.admin_id },
        { key: "google_code", val: this.resetArr.google_code },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialogReset = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>
