import { qspost } from '@/config/axios';

//获取管理员列表数据
export const getList = data => qspost("request?", data,"getAdmin");

//获取角色列表数据
export const getRole = data => qspost("request?",data,"getRole");

//添加管理员
export const add = data => qspost("request?", data,"addAdmin");

//冻结*解冻
export const freezeThaw = data => qspost("request?", data,"lockAdmin");

//删除管理员
export const del = data => qspost("request?", data,"deleteAdmin");

//重置密码
export const reset = data => qspost("request?", data,"adminResetPwd");
